import z from 'zod';

const WorkspaceProjectTaskShape = z.object({
    id: z.string(),
    name: z.string(),
    planned: z.boolean(),
    done: z.boolean(),
    createdAt: z.string(),
    updatedAt: z.string(),
    completedAt: z.string().or(z.null()),
});

const WorkspaceSharedProjectShape = z.object({
    workspaceId: z.string(),
    ownerId: z.string(),
});

const WorkspaceProjectShape = z.object({
    id: z.string(),
    name: z.string(),
    tasks: z.array(WorkspaceProjectTaskShape),
    createdAt: z.string(),
    updatedAt: z.string(),
    color: z.string().or(z.null()),
    shared: z.null().or(WorkspaceSharedProjectShape),
    completedTasksCount: z.number().or(z.null()),
});

const AutomationShape = z.object({
    id: z.string(),
    name: z.string(),
    completedAt: z.string().or(z.null()),
    origin: z.string(),
});

export const shapes = {
    ReadWorkspaceResponse: z.object({
        workspaceId: z.string(),
        projects: z.array(WorkspaceProjectShape),
        plannedTasks: z.array(z.string()),
        tasks: z.array(WorkspaceProjectTaskShape),
        archivedProjectsCount: z.number(),
        automations: z.array(AutomationShape),
    }),
};

export type ReadWorkspaceResponse = z.infer<
    typeof shapes.ReadWorkspaceResponse
>;

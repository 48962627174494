import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import './Portal.scss';

type PortalProps = {
  children: any;
  className?: string;
};

const Portal: React.FC<PortalProps> = ({ children, className }) => {
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    const target = document.body;
    const classList = ['portal-container'];

    if (className) className.split(' ').forEach((item) => classList.push(item));
    classList.forEach((item) => el.classList.add(item));
    target.appendChild(el);

    return () => {
      target.removeChild(el);
    };
  }, [el, className]);

  return ReactDOM.createPortal(children, el);
};

export default Portal;

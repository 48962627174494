import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import layoutReducer from './layoutSlice';
import toastsReducer from './toastsSlice';
import userReducer from './userSlice';
import workspaceReducer from './workspaceSlice';

const rootReducer = combineReducers({
    user: userReducer,
    layout: layoutReducer,
    toasts: toastsReducer,
    workspace: workspaceReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
};

export const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch: () => AppDispatch = useDispatch;

export { layoutReducer, toastsReducer, userReducer, workspaceReducer };

export default setupStore;

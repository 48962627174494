import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'api';
import { UpdateProjectCommand } from 'api/project-model';
import { AddTaskCommand } from 'api/task-model';
import { PlannedTaskAction, TaskAction, TaskId } from '../core/model';

export const addTask = createAsyncThunk(
    'tasks/addTask',
    async (command: AddTaskCommand, { rejectWithValue }) => {
        try {
            return Api.defaultApi()
                .addTask(command)
                .then((response) => {
                    return { request: command, response: response };
                });
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const deleteTask = createAsyncThunk(
    'tasks/deleteTask',
    async (
        props: { taskId: TaskId; command: UpdateProjectCommand },
        { rejectWithValue }
    ) => {
        try {
            return Api.defaultApi()
                .deleteTask(props.taskId, props.command)
                .then((response) => {
                    return { request: props, response: '' };
                });
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const taskAction = createAsyncThunk(
    'tasks/planTask',
    async (
        props: {
            taskId: TaskId;
            action: TaskAction;
            command: UpdateProjectCommand;
        },
        { rejectWithValue }
    ) => {
        try {
            return Api.defaultApi()
                .taskAction(props.taskId, props.action, props.command)
                .then((response) => {
                    return { request: props, response: '' };
                });
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const plannedTaskAction = createAsyncThunk(
    'tasks/completeTask',
    async (
        props: {
            taskId: TaskId;
            action: PlannedTaskAction;
            command: UpdateProjectCommand;
        },
        { rejectWithValue }
    ) => {
        try {
            return Api.defaultApi()
                .plannedTaskAction(props.taskId, props.action, props.command)
                .then((response) => {
                    return { request: props, response: '' };
                });
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const hideCompletedTasksAction = createAsyncThunk(
    'tasks/hideCompletedTasks',
    async (props: undefined, { rejectWithValue }) => {
        try {
            return Api.defaultApi().hideCompletedTasks();
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const idleAllPlannedTasksAction = createAsyncThunk(
    'tasks/idleAllPlannedTasks',
    async (props: undefined, { rejectWithValue }) => {
        try {
            return Api.defaultApi().clearAllPlannedTasks();
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

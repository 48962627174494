import { current } from '@reduxjs/toolkit';
import { ProjectId, WorkspaceId } from 'core/model';
import {
    addProject,
    archiveProject,
    deleteProject,
    fetchArchivedProjects,
    moveProject,
    renameProject,
    unarchiveProject,
} from 'store/projectsThunks';

export const projectsReducers = (builder: any) => {
    builder
        // addProject
        .addCase(addProject.pending, (state: any) => {
            state.error = null;
        })
        .addCase(addProject.fulfilled, (state: any, action: any) => {
            state.projects.push({
                id: action.payload.projectId as ProjectId,
                workspaceId: action.payload.workspaceId as WorkspaceId,
                projectRef: {
                    projectId: action.payload.projectId as ProjectId,
                    workspaceId: action.payload.workspaceId as WorkspaceId,
                },
                name: action.payload.name,
                color: action.payload.color,
                tasks: [],
                completedTasksCount: 0,
                shared: null,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
            });
        })
        .addCase(addProject.rejected, (state: any, action: any) => {
            state.error = action.error.message || 'An error occurred';
        })
        // renameProject
        .addCase(renameProject.pending, (state: any) => {
            state.error = null;
        })
        .addCase(renameProject.fulfilled, (state: any, action: any) => {
            state.projects
                .filter((p: any) => p.id === action.payload.request.projectId)
                .forEach(
                    (p: any) => (p.name = action.payload.request.command.name)
                );
        })
        .addCase(renameProject.rejected, (state: any, action: any) => {
            state.error = action.error.message || 'An error occurred';
        })
        // moveProject
        .addCase(moveProject.pending, (state: any) => {
            state.error = null;
        })
        .addCase(moveProject.fulfilled, (state: any, action: any) => {
            let projectsNewOrder = [...current(state.projects)];
            let item = projectsNewOrder[action.payload.itemIndex];
            projectsNewOrder.splice(action.payload.itemIndex, 1);

            state.projects =
                action.payload.itemIndex < 0
                    ? projectsNewOrder
                    : [
                          ...projectsNewOrder.slice(0, action.payload.newIndex),
                          item,
                          ...projectsNewOrder.slice(action.payload.newIndex),
                      ];
        })
        .addCase(moveProject.rejected, (state: any, action: any) => {
            state.error = action.error.message || 'An error occurred';
        })
        // fetchArchivedProjects
        .addCase(fetchArchivedProjects.pending, (state: any) => {
            state.archivedProjects.status = 'LOADING';
            state.archivedProjects.error = null;
        })
        .addCase(fetchArchivedProjects.fulfilled, (state: any, action: any) => {
            state.archivedProjects.status = 'SUCCEEDED';
            state.archivedProjects.projects = action.payload;
        })
        .addCase(fetchArchivedProjects.rejected, (state: any, action: any) => {
            state.archivedProjects.status = 'FAILED';
            state.archivedProjects.error =
                action.error.message || 'An error occurred';
        })
        // archiveProject
        .addCase(archiveProject.pending, (state: any, action: any) => {
            state.error = null;
        })
        .addCase(archiveProject.fulfilled, (state: any, action: any) => {
            const archivedProject = state.projects.find(
                (project: any) => project.id === action.payload
            );
            state.projects = state.projects.filter(
                (project: any) => project.id !== action.payload
            );
            if (archivedProject) {
                state.archivedProjects.projects.push({
                    id: archivedProject.id,
                    name: archivedProject.name,
                    color: archivedProject.color,
                });
            }
            state.archivedProjectsCount = state.archivedProjectsCount + 1;
        })
        .addCase(archiveProject.rejected, (state: any, action: any) => {
            state.error = action.error.message || 'An error occurred';
        })
        // unarchiveProject
        .addCase(unarchiveProject.pending, (state: any) => {
            state.error = null;
        })
        .addCase(unarchiveProject.fulfilled, (state: any, action: any) => {
            state.archivedProjects.projects =
                state.archivedProjects.projects.filter(
                    (project: any) => project.id !== action.payload.id
                );
            state.projects.push(action.payload);
            state.archivedProjectsCount = state.archivedProjectsCount - 1;
        })
        .addCase(unarchiveProject.rejected, (state: any, action: any) => {
            state.error = action.error.message || 'An error occurred';
        })
        // deleteProject
        .addCase(deleteProject.pending, (state: any) => {
            state.error = null;
        })
        .addCase(deleteProject.fulfilled, (state: any, action: any) => {
            state.projects = state.projects.filter(
                (project: any) => project.id !== action.payload
            );
        })
        .addCase(deleteProject.rejected, (state: any, action: any) => {
            state.error = action.error.message || 'An error occurred';
        });
};

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProject } from 'store/projectsThunks';
import { selectWorkspaceId } from 'store/userSlice';
import { selectWorkspaceData } from 'store/workspaceSlice';
import Button from '../../layout/atoms/Button';
import TextEditable from '../../layout/atoms/TextEditable';
import { AppDispatch } from '../../store';

const allColors = [
    '#ff9f45',
    '#009379',
    '#3366ff',
    '#ff6250',
    '#ffdf90',
    '#bfaff2',
    '#008c99',
    '#00c4cc',
    '#ff7eb9',
    '#a05a2c',
    '#b4d400',
    '#4b0082',
];
const getRandomNonTakenColor = (takenColors: string[]): string => {
    const availableColors = allColors.filter(
        (color) => !takenColors.includes(color)
    );

    if (availableColors.length === 0) {
        const randomRed = Math.floor(Math.random() * 256);
        const randomGreen = Math.floor(Math.random() * 256);
        const randomBlue = Math.floor(Math.random() * 256);
        return `rgb(${randomRed}, ${randomGreen}, ${randomBlue})`;
    }

    const randomIndex = Math.floor(Math.random() * availableColors.length);
    return availableColors[randomIndex];
};

export const AddProject = () => {
    const [newProjectName, setNewProjectName] = useState('');
    const workspaceId = useSelector(selectWorkspaceId);
    const workspaceData = useSelector(selectWorkspaceData);
    const availableColorProvider = () =>
        getRandomNonTakenColor(
            workspaceData.projects
                .filter((p) => !p.color)
                .map((p) => p.color) as string[]
        );

    const dispatch: AppDispatch = useDispatch();

    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    const doAddProject = (name: string) => {
        setIsAdding(true);
        return dispatch(
            addProject({
                workspaceId: workspaceId || '',
                name,
                color: availableColorProvider(),
            })
        )
            .finally(() => setIsAdding(false))
            .then((action) => {
                if (addProject.fulfilled.match(action)) {
                    setNewProjectName('');
                }
            });
    };

    const onAddConfirmedButtonClicked = () => {
        renameConfirmed();
    };

    const renameConfirmed = () => {
        if (newProjectName && newProjectName.trim()) {
            doAddProject(newProjectName.trim());
        }
    };

    const onAddNewProject = () => {
        setIsEditing(true);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const handleChange = (event: any) => {
        setNewProjectName(event.target.value);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            renameConfirmed();
        } else if (event.key === 'Escape') {
        }
    };

    if (isAdding) {
        return <>Adding project...</>;
    }

    if (isEditing) {
        return (
            <TextEditable
                value={newProjectName}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
                handleBlur={handleBlur}
                maxLength={100}
                placeholder="Enter text (max 100 characters)"
                onRenameConfirmedButtonClicked={onAddConfirmedButtonClicked}
            />
        );
    } else {
        return (
            <div>
                <Button theme="plain" onClick={onAddNewProject}>
                    <p>+ New Project</p>
                </Button>
            </div>
        );
    }
};

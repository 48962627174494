import Container from 'atoms/Container';
import {
    CSSProperties,
    forwardRef,
    HTMLAttributes,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
    selectWorkspaceData,
    updateStorePlannedTasksList,
    WorkspaceState,
} from 'store/workspaceSlice';

import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    rectSortingStrategy,
    SortableContext,
} from '@dnd-kit/sortable';
import { Environment } from 'Environment';
import axios from 'axios';
import {
    ProjectId,
    ProjectRef,
    TaskId,
    TaskState,
    TaskStateObject,
    WorkspaceId,
} from 'core/model';
import Grid from 'layout/atoms/Grid';
import TaskExecute from 'layout/atoms/TaskExecute';
import { useDispatch } from 'react-redux';
import { selectWorkspaceId } from 'store/userSlice';

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
    id: string;
    withOpacity?: boolean;
    isDragging?: boolean;
};

const Item = forwardRef<HTMLDivElement, ItemProps>(
    ({ id, withOpacity, isDragging, style, ...props }, ref) => {
        const inlineStyles: CSSProperties = {
            opacity: withOpacity ? '0.5' : '1',
            transformOrigin: '50% 50%',
            cursor: isDragging ? 'grabbing' : 'grab',
            ...style,
        };

        return (
            <div ref={ref} style={inlineStyles} {...props}>
                <TaskExecute key={id} id={id} state="planned" />
            </div>
        );
    }
);

export type PlannedTaskOrigin = 'workspace' | 'project' | 'automation';

export type PlannedTask = {
    task: TaskStateObject;
    color?: string | null;
    projectName?: string;
    projectRef: ProjectRef;
    origin: PlannedTaskOrigin;
};

const PlannedTasksList = () => {
    const dispatch = useDispatch();
    const workspaceId = useSelector(selectWorkspaceId);
    const workspaceData: WorkspaceState = useSelector(selectWorkspaceData);
    const activeTasks: PlannedTask[] = [
        ...workspaceData.tasks
            .filter(
                (t) => t.done || workspaceData.plannedTasks.indexOf(t.id) >= 0
            )
            .map((task) => {
                return {
                    task,
                    projectRef: {
                        workspaceId: workspaceData.id as WorkspaceId,
                        projectId: workspaceData.id as ProjectId,
                    },
                    origin: 'workspace' as PlannedTaskOrigin,
                };
            }),
        ...workspaceData.projects.flatMap((p) => {
            return p.tasks
                .filter(
                    (t: any) =>
                        t.done || workspaceData.plannedTasks.indexOf(t.id) >= 0
                )
                .map((task: any) => {
                    return {
                        task,
                        color: p.color,
                        projectName: p.name,
                        projectRef: {
                            workspaceId: p.workspaceId as WorkspaceId,
                            projectId: p.id,
                        },
                        origin: 'project' as PlannedTaskOrigin,
                    };
                });
        }),
        ...workspaceData.automations.map((a) => {
            return {
                task: {
                    id: a.id,
                    name: a.name,
                    planned: true,
                    state: 'PLANNED' as TaskState,
                    createdAt: new Date().toISOString(),
                    completedAt: a.completedAt,
                    done: false,
                },
                projectRef: {
                    workspaceId: workspaceData.id as WorkspaceId,
                    projectId: a.id as ProjectId,
                },
                origin: 'automation' as PlannedTaskOrigin,
            };
        }),
    ].sort(
        (t1, t2) =>
            workspaceData.plannedTasks.indexOf(t1.task.id) -
            workspaceData.plannedTasks.indexOf(t2.task.id)
    );
    const partitionedTasks = activeTasks.reduce(
        (acc, plannedTask) => {
            const group: PlannedTask[] =
                acc[plannedTask.task.completedAt === null ? 0 : 1];
            group.push(plannedTask);
            return acc;
        },
        [[], []]
    );
    const plannedTasks: PlannedTask[] = partitionedTasks[0];
    const completedTasks: PlannedTask[] = partitionedTasks[1];
    const displaySeparator =
        plannedTasks.length > 0 && completedTasks.length > 0;
    const [items, setItems] = useState<string[]>([]);
    const [activeId, setActiveId] = useState<string | null>(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    useEffect(() => {
        workspaceData.status === 'SUCCEEDED' &&
            setItems(plannedTasks.map((task: PlannedTask) => task.task.id));
    }, [workspaceData]);

    const putToFront = useCallback(
        (taskId: string) => {
            return axios.put(
                `${Environment.backendUrl}/api/v1/workspace/tasks/${taskId}/put-to-front`,
                {
                    workspaceId: workspaceId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'hustleToken'
                        )}`,
                    },
                }
            );
        },
        [workspaceId]
    );

    const reorderAfterTask = useCallback(
        (taskId: string, afterTaskId: TaskId) => {
            return axios.put(
                `${Environment.backendUrl}/api/v1/workspace/tasks/${taskId}/reorder`,
                {
                    workspaceId: workspaceId,
                    afterTaskId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'hustleToken'
                        )}`,
                    },
                }
            );
        },
        [workspaceId]
    );

    const handleDragStart = useCallback((event: DragStartEvent) => {
        setTimeout(() => setActiveId(event.active.id.toString()), 100);
    }, []);

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            if (active.id !== over?.id) {
                setItems((prevItems) => {
                    const oldIndex = prevItems.indexOf(active.id.toString());
                    const newIndex = prevItems.indexOf(over!.id.toString());

                    if (newIndex === 0) {
                        putToFront(active.id.toString());
                    } else {
                        reorderAfterTask(
                            active.id.toString(),
                            prevItems[newIndex - 1]
                        );
                    }

                    const newItemsOrder = arrayMove(
                        prevItems,
                        oldIndex,
                        newIndex
                    );

                    dispatch(updateStorePlannedTasksList(newItemsOrder));
                    return newItemsOrder;
                });

                setActiveId(null);
            }
        },
        [dispatch, putToFront, reorderAfterTask]
    );

    const handleDragCancel = useCallback(() => {
        setActiveId(null);
    }, []);

    return (
        <Container narrow gap="16">
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
            >
                <SortableContext items={items} strategy={rectSortingStrategy}>
                    <Grid columns={1} gap={0}>
                        {items.map((taskId) => (
                            <TaskExecute
                                key={taskId}
                                id={taskId}
                                state="planned"
                            />
                        ))}
                    </Grid>
                </SortableContext>
                <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
                    {activeId ? <Item id={activeId} isDragging /> : null}
                </DragOverlay>
            </DndContext>
        </Container>
    );
};

export default PlannedTasksList;

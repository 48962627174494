import Api from 'api';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit2.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import Button from 'atoms/Button';
import Container from 'atoms/Container';
import { useEffect, useState } from 'react';

import ContentBox from 'atoms/ContentBox';
import Grid from 'atoms/Grid';
import ListHeader from 'atoms/ListHeader/ListHeader';

type Automation = { id: string; name: string; status: 'OFF' | 'ON' };

export const AutomationConfigPage = () => {
    const [automations, setAutomations] = useState<Automation[]>([]);

    useEffect(() => {
        Api.defaultApi()
            .fetchAutomations()
            .then((response) => {
                setAutomations(response as Automation[]);
            });
    }, []);

    const deleteAutomation = (id: string) => {
        Api.defaultApi()
            .deleteAutomation(id)
            .then(() => {
                setAutomations(
                    automations.filter((automation) => automation.id !== id)
                );
            });
    };

    return (
        <>
            <Container>
                <ListHeader text="Configure Automation">
                    {automations.length > 0 && (
                        <Button size="m" theme="blank" to="/automation">
                            <CheckmarkIcon /> <p>Done</p>
                        </Button>
                    )}
                </ListHeader>
            </Container>
            <Container narrow>
                <Grid columns={1} gap={12}>
                    {automations.length === 0 && (
                        <div>There are no automations configured</div>
                    )}

                    {automations.length > 0 &&
                        automations.map((automation, index) => (
                            <ContentBox>
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>{automation.name}</div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            gap: '12px',
                                        }}
                                    >
                                        <div>{automation.status}</div>
                                        <Button
                                            size="s"
                                            theme="plain"
                                            /*to={`/automation/${automation.id}/edit`}*/
                                        >
                                            <EditIcon /> <p>Edit</p>
                                        </Button>
                                        <Button
                                            size="s"
                                            theme="plain"
                                            onClick={() =>
                                                deleteAutomation(automation.id)
                                            }
                                        >
                                            <TrashIcon /> <p>Delete</p>
                                        </Button>
                                    </div>
                                </div>
                            </ContentBox>
                        ))}
                    <Button
                        size="m"
                        theme="plain"
                        to="/automation/configure/create"
                    >
                        <p>+ New automation</p>
                    </Button>
                    <Button size="m" theme="blank" to="/automation">
                        <p>Go Back</p>
                    </Button>
                </Grid>
            </Container>
        </>
    );
};

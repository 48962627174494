import { ReactComponent as DragIcon } from 'assets/icons/drag-indicator.svg';
import Task from 'atoms/Task';
import AddTask from 'containers/Plan/AddTask';
import { ProjectState } from 'core/model';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';
import { LayoutState } from 'store/layoutSlice';
import { addTask } from 'store/tasksThunks';
import { selectProject } from 'store/workspaceSlice';
import Header from './Header';
import styles from './Project.module.scss';

const Project = (props: {
    id: string;
    folded?: boolean;
    onProjectDelete: any;
    onProjectArchive: any;
    dragHandleProps?: any;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const layoutState: LayoutState = useSelector((state: any) => state.layout);
    const [hidden, setHidden] = useState(false);

    const projectData: ProjectState = useSelector((state: any) =>
        selectProject(state, props.id)
    );

    const completedTasksCount =
        (projectData.completedTasksCount || 0) +
            projectData.tasks.filter((t) => t.completedAt).length || 0;

    useEffect(() => {
        setHidden(layoutState.projectsReorder);
    }, [layoutState.projectsReorder]);

    return (
        <div
            className={`${styles.wrap} ${
                (props.folded || hidden) && styles.hidden
            }`}
        >
            <div
                className={styles.indicator}
                style={{ backgroundColor: projectData.color || '' }}
                {...props.dragHandleProps}
            >
                <DragIcon />
            </div>
            <Header
                workspaceId={projectData.workspaceId}
                project={projectData}
                onProjectDelete={props.onProjectDelete}
                onProjectArchive={props.onProjectArchive}
            />
            {projectData.shared && (
                <small>Shared from: {projectData?.shared.ownerId}</small>
            )}
            {
                <div className={styles.main}>
                    {projectData.tasks
                        .filter((t) => t.completedAt === null)
                        .map((task) => (
                            <Task
                                projectRef={projectData?.projectRef}
                                key={task.id}
                                task={task}
                            />
                        ))}
                </div>
            }
            <div className={styles.footer}>
                <AddTask
                    color={projectData?.color}
                    placeholder={'What needs to be done for this project?'}
                    onTasksAdded={async (tasks) => {
                        for (let i = 0; i < tasks.length; i++) {
                            const t = tasks[i];
                            await dispatch(
                                addTask({
                                    workspaceId: projectData.workspaceId,
                                    projectId: projectData.id,
                                    name: t,
                                    planned: false,
                                })
                            );
                        }
                    }}
                />
                {completedTasksCount > 0 && (
                    <small>
                        You have completed {completedTasksCount} task
                        {completedTasksCount === 1 ? '' : 's'}
                    </small>
                )}
            </div>
        </div>
    );
};

export default Project;

import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
    ArchivedProjectState,
    ProjectState,
    TaskStateObject,
} from '../core/model';
import { RootState } from './index';
import { projectsReducers } from './reducers/projectsReducers';
import { tasksReducers } from './reducers/tasksReducers';
import { workspaceReducers } from './reducers/workspaceReducers';

export interface WorkspaceState {
    id: string;
    projects: ProjectState[];
    archivedProjectsCount: number;
    archivedProjects: {
        projects: ArchivedProjectState[];
        status: string;
        error: string | null;
    };
    tasks: TaskStateObject[];
    plannedTasks: string[];
    automations: any[];
    status: string;
    error: string | null;
}

const initialState: WorkspaceState = {
    id: '',
    projects: [],
    archivedProjectsCount: 0,
    archivedProjects: {
        projects: [],
        status: 'IDLE',
        error: null,
    },
    tasks: [],
    plannedTasks: [],
    automations: [],
    status: 'IDLE',
    error: null,
};

const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        updateStorePlannedTasksList: (state, action) => {
            state.plannedTasks = action.payload;
        },
    },
    extraReducers: (builder) => {
        workspaceReducers(builder);
        projectsReducers(builder);
        tasksReducers(builder);
    },
});

export const selectWorkspaceData = (state: RootState) => {
    return state.workspace;
};

export const selectProject = (state: RootState, projectId: string) => {
    return state.workspace.projects.filter(
        (project: ProjectState) => project.id === projectId
    )[0];
};

export const selectTask = createSelector(
    [
        (state: RootState, taskId: string | undefined) => {
            const mergedTasks = state.workspace.projects.flatMap((project) =>
                project.tasks.map((task) => ({
                    ...task,
                    projectRef: {
                        id: project.id,
                        name: project.name,
                        color: project.color,
                        workspaceId: project.workspaceId,
                    },
                }))
            );
            const automationsTasks = state.workspace.automations.map(
                (task) => ({
                    ...task,
                    automationId: task.id,
                    projectRef: {
                        id: state.workspace.id,
                        name: 'Automation',
                        color: '#c6c6c6',
                        workspaceId: state.workspace.id,
                    },
                })
            );
            const otherTasks = state.workspace.tasks.map((task) => ({
                ...task,
                projectRef: {
                    id: state.workspace.id,
                    color: '#c6c6c6',
                    workspaceId: state.workspace.id,
                },
            }));
            const allTasks = [
                ...mergedTasks,
                ...automationsTasks,
                ...otherTasks,
            ];

            return allTasks.filter((task: any) => task.id === taskId)[0];
        },
    ],
    (task: any) => task
);

export const { updateStorePlannedTasksList } = workspaceSlice.actions;

export default workspaceSlice.reducer;

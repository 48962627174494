import { clsx } from 'clsx';
import { useState } from 'react';

import { ReactComponent as CheckmarkSvg } from 'assets/icons/checkmark.svg';
import successSoundYay from 'assets/sounds/success-yay.mp3';
import styles from './TaskCheckbox.module.scss';

type TaskCheckboxProps = {
    color?: string;
    checked: boolean;
    onChange: any;
};

const TaskCheckbox = ({ color, checked, onChange }: TaskCheckboxProps) => {
    const [checkedState, setCheckedState] = useState<boolean>(checked);

    const playSound = () => {
        new Audio(successSoundYay).play();
    };

    const handleOnChange = (e: any) => {
        e.preventDefault();
        !checkedState && playSound();
        let newValue = !checkedState;
        setCheckedState(newValue);
        setTimeout(() => onChange(newValue), 500);
    };

    return (
        <div
            className={clsx(styles.wrap, { [styles.checked]: checkedState })}
            style={{ color: color || '#000000' }}
            onClick={handleOnChange}
        >
            <div
                className={styles.background}
                style={{ backgroundColor: color }}
            />
            <CheckmarkSvg className={styles.checkmark} />
            <input
                type="checkbox"
                name="name"
                value="value"
                checked={checkedState}
                className={styles.checkbox}
                readOnly
                // onChange={handleOnChange}
            />
        </div>
    );
};

export default TaskCheckbox;

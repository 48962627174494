import { TaskState } from 'core/model';
import {
    addTask,
    deleteTask,
    hideCompletedTasksAction,
    idleAllPlannedTasksAction,
    plannedTaskAction,
    taskAction,
} from '../tasksThunks';

export const tasksReducers = (builder: any) => {
    builder // addTask
        .addCase(addTask.pending, (state: any) => {
            state.error = null;
        })
        .addCase(addTask.fulfilled, (state: any, action: any) => {
            const _planned = action.payload.request.planned || false;
            const _state = _planned ? 'PLANNED' : 'IDLE';
            const newTask = {
                id: action.payload.response.id,
                name: action.payload.request.name,
                planned: _planned,
                done: action.payload.response.done,
                createdAt: new Date().toISOString(),
                completedAt: null,
                state: _state as TaskState,
            };
            if (state.id === action.payload.response.projectId) {
                state.tasks.push(newTask);
            } else {
                state.projects
                    .filter(
                        (p: any) => p.id === action.payload.response.projectId
                    )
                    .forEach((p: any) => p.tasks.push(newTask));
            }
            if (
                state.plannedTasks.indexOf(action.payload.response.id) === -1 &&
                _planned
            ) {
                state.plannedTasks.push(action.payload.response.id);
            }
        })
        .addCase(addTask.rejected, (state: any, action: any) => {
            state.error = action.error.message || 'An error occurred';
        })
        // deleteTask
        .addCase(deleteTask.pending, (state: any) => {
            state.error = null;
        })
        .addCase(deleteTask.fulfilled, (state: any, action: any) => {
            state.plannedTasks = state.plannedTasks.filter(
                (tid: any) => tid !== action.payload.request.taskId
            );
            state.tasks = state.tasks.filter(
                (t: any) => t.id !== action.payload.request.taskId
            );
            state.projects
                .filter(
                    (p: any) =>
                        p.id === action.payload.request.command.projectId
                )
                .forEach(
                    (p: any) =>
                        (p.tasks = p.tasks.filter(
                            (t: any) => t.id !== action.payload.request.taskId
                        ))
                );
        })
        .addCase(deleteTask.rejected, (state: any, action: any) => {
            state.error = action.error.message || 'An error occurred';
        })
        // taskAction
        .addCase(taskAction.pending, (state: any) => {
            state.error = null;
        })
        .addCase(taskAction.fulfilled, (state: any, action: any) => {
            [
                ...state.tasks.filter(
                    (t: any) => t.id === action.payload.request.taskId
                ),
                ...state.projects
                    .filter(
                        (p: any) =>
                            p.id === action.payload.request.command.projectId
                    )
                    .flatMap((p: any) =>
                        p.tasks.filter(
                            (t: any) => t.id === action.payload.request.taskId
                        )
                    ),
            ].forEach((t) => {
                if (action.payload.request.action === 'PLAN') {
                    t.state = 'PLANNED';
                    state.plannedTasks.push(t.id);
                } else {
                    t.state = 'IDLE';
                    state.plannedTasks = state.plannedTasks.filter(
                        (tid: any) => tid !== t.id
                    );
                }
            });
            state.tasks = [...state.tasks];
            state.projects = [...state.projects];
        })
        .addCase(taskAction.rejected, (state: any, action: any) => {
            state.error = action.error.message || 'An error occurred';
        })
        // plannedTaskAction
        .addCase(plannedTaskAction.pending, (state: any) => {
            state.error = null;
        })
        .addCase(plannedTaskAction.fulfilled, (state: any, action: any) => {
            [
                ...state.tasks.filter(
                    (t: any) => t.id === action.payload.request.taskId
                ),
                ...state.projects
                    .filter(
                        (p: any) =>
                            p.id === action.payload.request.command.projectId
                    )
                    .flatMap((p: any) =>
                        p.tasks.filter(
                            (t: any) => t.id === action.payload.request.taskId
                        )
                    ),
            ].forEach((t) => {
                if (action.payload.request.action === 'COMPLETE') {
                    t.state = 'DONE';
                    t.done = true;
                    t.completedAt = new Date().toISOString();
                } else {
                    t.state = 'PLANNED';
                    t.done = false;
                    t.completedAt = null;
                }
            });
            state.tasks = [...state.tasks];
            state.projects = [...state.projects];
        })
        .addCase(plannedTaskAction.rejected, (state: any, action: any) => {
            state.error = action.error.message || 'An error occurred';
        })
        // hideCompletedTasksAction
        .addCase(hideCompletedTasksAction.pending, (state: any) => {
            state.error = null;
        })
        .addCase(
            hideCompletedTasksAction.fulfilled,
            (state: any, action: any) => {
                state.tasks = state.tasks.filter(
                    (t: any) => t.completedAt === null
                );
                state.projects.forEach(
                    (p: any) =>
                        (p.tasks = p.tasks.filter(
                            (t: any) => t.completedAt === null
                        ))
                );
                state.projects = [...state.projects];
            }
        )
        .addCase(
            hideCompletedTasksAction.rejected,
            (state: any, action: any) => {
                state.error = action.error.message || 'An error occurred';
            }
        )
        // idleAllPlannedTasksAction
        .addCase(idleAllPlannedTasksAction.pending, (state: any) => {
            state.error = null;
        })
        .addCase(idleAllPlannedTasksAction.fulfilled, (state: any) => {
            const workspaceIdleTasks = state.tasks
                .filter((t: any) => t.state === 'PLANNED')
                .map((t: any) => {
                    t.state = 'IDLE';
                    t.done = false;
                    t.completedAt = null;

                    return t.id;
                });
            const projectsIdleTasks = state.projects.flatMap((p: any) =>
                p.tasks
                    .filter((t: any) => t.state === 'PLANNED')
                    .map((t: any) => {
                        t.state = 'IDLE';
                        t.done = false;
                        t.completedAt = null;

                        return t.id;
                    })
            );
            state.projects = [...state.projects];
            state.plannedTasks = state.plannedTasks.filter(
                (id: any) =>
                    workspaceIdleTasks.indexOf(id) === -1 &&
                    projectsIdleTasks.indexOf(id) === -1
            );
        })
        .addCase(
            idleAllPlannedTasksAction.rejected,
            (state: any, action: any) => {
                state.error = action.error.message || 'An error occurred';
            }
        );
};

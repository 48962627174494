import Button from 'layout/atoms/Button';
import TextEditable from 'layout/atoms/TextEditable';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { Environment } from '../../Environment';

import GmailIcon from 'assets/3rd-party/gmail.png';
import OutlookIcon from 'assets/3rd-party/outlook.png';

export const MagicLink = (props: { mode: 'GET_EMAIL' | 'EMAIL_SENT' }) => {
    const location = useLocation();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState<string>('');

    const handleChange = (event: any) => {
        const value = event.target.value.toLowerCase().trim();
        setEmail(value);

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailPattern.test(value)) {
            setValidEmail(value);
        } else {
            setValidEmail('');
        }
    };
    const onAddConfirmedButtonClicked = () => {};
    const queryParams = new URLSearchParams(location.search);
    const debugLink = queryParams.get('debugLink');
    const debugCallback = Environment.isLocalhost
        ? '&callback=' + encodeURIComponent('http://localhost:3000')
        : '';
    return (
        <>
            {props.mode === 'EMAIL_SENT' ? (
                <div style={{ textAlign: 'center', margin: '96px 0 48px' }}>
                    <h1>Check your email for a link</h1>
                    <h3>
                        We’ve sent a magic-link to your email address. The link
                        will expire shortly, so please use it as soon as
                        possible.
                    </h3>
                    {debugLink && (
                        <>
                            <br />
                            <br />
                            <br />
                            <h4>Debug link:</h4>
                            <h5>
                                <a href={`${debugLink}${debugCallback}`}>
                                    {`${debugLink}${debugCallback}`}
                                </a>
                            </h5>
                        </>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '24px',
                        }}
                    >
                        <a
                            href={'https://mail.google.com/mail/u/0/'}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: 'currentcolor',
                            }}
                        >
                            <img
                                src={GmailIcon}
                                alt="Icon"
                                style={{ marginRight: '8px', width: '20px' }}
                            />
                            <span>Open Gmail</span>
                        </a>
                        <a
                            href={'https://outlook.live.com/mail/0/inbox'}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: 'currentcolor',
                            }}
                        >
                            <img
                                src={OutlookIcon}
                                alt="Icon"
                                style={{ marginRight: '8px' }}
                            />
                            <span>Open Outlook</span>
                        </a>
                    </div>
                    <h4>Can’t find the message? Check your spam folder!</h4>
                </div>
            ) : (
                <></>
            )}
            {props.mode === 'GET_EMAIL' ? (
                <div className="container" style={{ margin: '96px 0 48px' }}>
                    <TextEditable
                        value={email}
                        handleChange={handleChange}
                        maxLength={100}
                        placeholder="email address"
                        onRenameConfirmedButtonClicked={
                            onAddConfirmedButtonClicked
                        }
                    />
                    {validEmail && (
                        <div>
                            <a
                                href={`${
                                    Environment.backendUrl
                                }/auth/magic/authorize?email=${encodeURIComponent(
                                    validEmail
                                )}${debugCallback}`}
                                rel="noreferrer"
                            >
                                <Button theme="plain">
                                    <p>Send me the link</p>
                                </Button>
                            </a>
                        </div>
                    )}
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

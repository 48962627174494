import Api from 'api';
import { ReactComponent as AutomationDoneIcon } from 'assets/icons/automation-done.svg';
import { ReactComponent as AutomationErrorIcon } from 'assets/icons/automation-error.svg';
import { ReactComponent as AutomationIdleIcon } from 'assets/icons/automation-idle.svg';
import { ReactComponent as AutomationWarningIcon } from 'assets/icons/automation-warn.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-square.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import Button from 'atoms/Button';
import Container from 'atoms/Container';
import ContentBox from 'atoms/ContentBox';
import ListHeader from 'atoms/ListHeader/ListHeader';
import { useEffect, useState } from 'react';

type AutomationStatus = {
    id: string;
    name: string;
    status: 'IDLE' | 'ACTIVE' | 'WARNING' | 'DONE' | 'ERROR';
};

export const AutomationPage = () => {
    const [automations, setAutomations] = useState<AutomationStatus[]>([]);

    useEffect(() => {
        Api.defaultApi()
            .fetchAutomationStatus()
            .then((response) => {
                setAutomations(response.automations as AutomationStatus[]);
            });
    }, []);

    const currentMonth = new Date().toLocaleString('default', {
        month: 'long',
    });
    return (
        <>
            <Container>
                <ListHeader text="Automation">
                    {automations.length > 0 && (
                        <Button
                            size="m"
                            theme="blank"
                            shape="square"
                            to="/automation/configure"
                        >
                            <SettingsIcon />
                        </Button>
                    )}
                </ListHeader>
            </Container>
            <Container narrow>
                {automations.length === 0 ? (
                    <ContentBox>
                        <div>There are no automations configured</div>
                        <Button
                            size="m"
                            theme="primary"
                            to="/automation/configure/create"
                        >
                            <PlusIcon /> <p>Add first automation</p>
                        </Button>
                    </ContentBox>
                ) : (
                    <ContentBox>
                        <ListHeader
                            text={`Month of ${currentMonth}`}
                        ></ListHeader>
                        {automations.length > 0 &&
                            automations
                                .sort((a, b) =>
                                    a.status.localeCompare(b.status)
                                )
                                .map((automation, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            margin: '12px',
                                            gap: '12px',
                                            width: '100%',
                                        }}
                                    >
                                        <>
                                            {automation.status === 'DONE' && (
                                                <AutomationDoneIcon
                                                    style={{
                                                        maxHeight: '24px',
                                                    }}
                                                />
                                            )}
                                            {automation.status === 'IDLE' && (
                                                <AutomationIdleIcon
                                                    style={{
                                                        maxHeight: '24px',
                                                    }}
                                                />
                                            )}
                                            {automation.status === 'ERROR' && (
                                                <AutomationErrorIcon
                                                    style={{
                                                        maxHeight: '24px',
                                                    }}
                                                />
                                            )}
                                            {automation.status === 'ACTIVE' && (
                                                <AutomationWarningIcon
                                                    style={{
                                                        maxHeight: '24px',
                                                    }}
                                                />
                                            )}
                                            {automation.status ===
                                                'WARNING' && (
                                                <AutomationWarningIcon
                                                    style={{
                                                        maxHeight: '24px',
                                                    }}
                                                />
                                            )}
                                        </>
                                        <div>{automation.name}</div>
                                    </div>
                                ))}
                    </ContentBox>
                )}
            </Container>
        </>
    );
};

export default AutomationPage;

import Api from 'api';
import Button from 'atoms/Button';
import Container from 'atoms/Container';
import ContentBox from 'atoms/ContentBox';
import Grid from 'atoms/Grid';
import Input from 'atoms/Input/Input';
import ListHeader from 'atoms/ListHeader/ListHeader';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { UserState } from 'store/userSlice';

export const AutomationCreatePage = () => {
    const userState: UserState = useSelector((state: any) => state.user);
    const [saving, setSaving] = useState(false);
    const [selectedDay, setSelectedDay] = useState<number | null>(null);

    const [automationName, setAutomationName] = useState<string>('');

    const [taskName, setTaskName] = useState<string>('');

    const navigate = useNavigate();

    const updateAutomationNamePlaceholder = (
        task: string,
        day: number | null
    ) => {
        if (day === null) {
            setAutomationName('');
            return;
        }
        setAutomationName(`Monthly on ${day}th add '${task}' in Execute`);
    };

    const selectDay = (day: number) => {
        setSelectedDay(day);
        automationName === '' &&
            updateAutomationNamePlaceholder(taskName || 'Task name', day);
    };

    const handleAutomationNameChange = (event: any) => {
        if (event.target.value.endsWith('\n')) {
            return;
        }
        setAutomationName(event.target.value);
    };

    const handleTaskNameChange = (event: any) => {
        if (event.target.value.endsWith('\n')) {
            return;
        }
        setTaskName(event.target.value);
        automationName === '' &&
            updateAutomationNamePlaceholder(event.target.value, selectedDay);
    };

    const save = () => {
        if (selectedDay === null || saving) {
            return;
        }
        setSaving(true);

        const name = automationName ? automationName.trim() : undefined;

        const steps: {
            type: 'TASK';
            payload: any;
        }[] = [
            {
                type: 'TASK',
                payload: {
                    type: 'TASK',
                    taskName,
                },
            },
        ];

        Api.defaultApi()
            .createAutomation(
                userState?.data?.workspaceId,
                name,
                selectedDay,
                steps
            )
            .then((response) => {
                navigate('/automation/configure');
            })
            .finally(() => setSaving(false));
    };

    const nextExecutionDate = (day: number | null) => {
        if (day === null) {
            return null;
        }
        const now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth();

        // If the day has already passed this month, schedule for next month
        if (now.getDate() >= day) {
            month++;
            if (month > 11) {
                // Handle December case
                month = 0;
                year++;
            }
        }

        // Create date and adjust if the day is invalid for the month (e.g., Feb 30)
        const nextDate = new Date(year, month, day);
        if (nextDate.getDate() !== day) {
            nextDate.setDate(0); // Last valid day of the month
        }
        return nextDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    return (
        <>
            <Container narrow>
                <ListHeader text="New Automation" />
            </Container>
            <Container narrow>
                <Grid columns={1} gap={12}>
                    <ContentBox>
                        <Grid columns={1} gap={24}>
                            <div style={{ textAlign: 'left' }}>
                                <p
                                    style={{
                                        margin: ' 0 0 4px',
                                        fontWeight: 600,
                                    }}
                                >
                                    Select day of the month:
                                </p>
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(7, 1fr)',
                                        gap: '8px',
                                        width: 'fit-content',
                                        margin: '0',
                                    }}
                                >
                                    {Array.from(
                                        { length: 5 },
                                        (_, i) => i + 1
                                    ).map((week) =>
                                        Array.from(
                                            { length: 7 },
                                            (_, i) => i + 1 + (week - 1) * 7
                                        )
                                            .filter((day) => day <= 31)
                                            .map((day) => (
                                                <Button
                                                    size="s"
                                                    theme={
                                                        selectedDay == day
                                                            ? 'primary'
                                                            : 'plain'
                                                    }
                                                    shape="square"
                                                    key={day}
                                                    onClick={() =>
                                                        selectDay(day)
                                                    }
                                                >
                                                    <p>{day}</p>
                                                </Button>
                                            ))
                                    )}
                                </div>
                                {selectedDay !== null && (
                                    <p style={{ margin: '12px 0 0' }}>
                                        This automation will trigger on{' '}
                                        {nextExecutionDate(selectedDay)}.
                                        <br />
                                        {selectedDay > 28 && (
                                            <>
                                                In case of shorter months, the
                                                last day of the month will be
                                                selected.
                                            </>
                                        )}
                                    </p>
                                )}
                            </div>
                            <Input
                                name="task-name"
                                label="Task name:"
                                value={taskName}
                                handleChange={handleTaskNameChange}
                                maxLength={100}
                                placeholder="Displays on Execute list"
                            />
                            <Input
                                name="automation-name"
                                label="Automation name:"
                                value={automationName}
                                handleChange={handleAutomationNameChange}
                                maxLength={100}
                                placeholder="Will display in automate list"
                            />
                        </Grid>
                    </ContentBox>
                    <Button size="m" theme="primary" onClick={save}>
                        <p>Save</p>
                    </Button>
                    <Button size="m" theme="plain" to="/automation/configure">
                        <p>Cancel</p>
                    </Button>
                </Grid>
            </Container>
        </>
    );
};

import InitLoader from 'molecules/InitLoader';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectWorkspaceId } from 'store/userSlice';
import { fetchWorkspace } from 'store/workspaceThunks';

import ExecutePage from 'containers/Execute';
import PlanPage from 'containers/Plan';
import { AppDispatch } from 'store';
import { LayoutState, selectLayout } from 'store/layoutSlice';
import { selectWorkspaceData } from 'store/workspaceSlice';

type WorkspaceState = 'EXECUTE' | 'PLAN';

const Workspace = (props: { workspaceState: WorkspaceState }) => {
    let navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const workspaceData = useSelector(selectWorkspaceData);
    const layoutState: LayoutState = useSelector(selectLayout);
    const workspaceId = useSelector(selectWorkspaceId);
    const workspaceState = props.workspaceState;

    const handleKeyDown = useCallback(
        (event: any) => {
            if (event.key === '1') {
                navigate('/plan');
            } else if (event.key === '2') {
                navigate('/execute');
            }
        },
        [navigate]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (workspaceId) {
            dispatch(fetchWorkspace(workspaceId));
        }
    }, [dispatch, workspaceId]);

    useEffect(() => {
        layoutState.view === 'list'
            ? document.body.classList.remove('grid-view')
            : document.body.classList.add('grid-view');
    }, [layoutState.view]);

    useEffect(() => {
        layoutState.theme === 'light'
            ? document.body.classList.remove('dark-theme')
            : document.body.classList.add('dark-theme');
    }, [layoutState.theme]);

    if (workspaceData.status === 'IDLE' || workspaceData.status === 'LOADING') {
        return <InitLoader />;
    }

    if (workspaceData.status === 'FAILED') {
        return (
            <div style={{ margin: '32px' }}>
                <p>Nieznany bląd aplikacji: {workspaceData?.error}</p>
            </div>
        );
    }

    if (workspaceData.status === 'SUCCEEDED') {
    }

    return (
        <>
            {workspaceData.status === 'SUCCEEDED' &&
                workspaceState === 'PLAN' && <PlanPage />}
            {workspaceData.status === 'SUCCEEDED' &&
                workspaceState === 'EXECUTE' && <ExecutePage />}
        </>
    );
};

export default Workspace;

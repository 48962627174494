import Container from 'atoms/Container';
import Button from 'layout/atoms/Button';
import Grid from 'layout/atoms/Grid';
import ProjectsList from 'layout/organisms/ProjectsList/ProjectsList';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectWorkspaceData } from 'store/workspaceSlice';
import { AddProject } from './AddProject';
import { ProjectsArchive } from './ProjectsArchive';
import WorkspaceTasks from './WorkspaceTasks';

const PlanPage = () => {
    const workspaceData = useSelector(selectWorkspaceData);
    const [displayArchive, setDisplayArchive] = useState<boolean>(false);

    useEffect(() => {}, []);

    return (
        <>
            <Container narrow>
                <WorkspaceTasks />
            </Container>
            <ProjectsList />
            <Container narrow>
                <Grid columns={1} gap={12}>
                    {workspaceData.projects.length === 0 && (
                        <div className={'empty-state'}>
                            <small>
                                You can organize your tasks into projects,
                                <br /> which are more manageable and effectively
                                encompass work units.
                            </small>
                        </div>
                    )}
                    <AddProject />
                    <div>
                        {workspaceData.archivedProjectsCount > 0 &&
                            !displayArchive && (
                                <Button
                                    theme="blank"
                                    onClick={() => setDisplayArchive(true)}
                                >
                                    Show archived projects (
                                    {workspaceData.archivedProjectsCount})
                                </Button>
                            )}
                        {workspaceData.archivedProjectsCount > 0 &&
                            displayArchive && (
                                <Button
                                    theme="blank"
                                    onClick={() => setDisplayArchive(false)}
                                >
                                    Hide archived projects
                                </Button>
                            )}
                    </div>
                </Grid>
            </Container>
            <Container narrow>
                {workspaceData.archivedProjectsCount > 0 && displayArchive && (
                    <ProjectsArchive />
                )}
            </Container>
        </>
    );
};

export default PlanPage;

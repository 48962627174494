import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../store';
import { logout, UserState } from '../../../store/userSlice';

import Button from '../../atoms/Button';
import Container from '../../atoms/Container';
import Dropdown from '../../atoms/Dropdown';
import Logo from '../../atoms/Logo';
import ThemeToggle from '../../atoms/ThemeToggle';

import styles from './Topbar.module.scss';
import { ReactComponent as PowerIcon } from '../../../assets/icons/power.svg';
import { ReactComponent as AutomateIcon } from '../../../assets/icons/recuring.svg';
import { ReactComponent as PricetagIcon } from '../../../assets/icons/pricetag.svg';
import Separator from '../../atoms/Separator';
import FeatureFlag from '../../../feature-flag/FeatureFlag';

export const Topbar = (props: { active: boolean }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [scrollOnTop, setScrollOnTop] = useState<boolean>(true);
    const userState: UserState = useSelector((state: any) => state.user);

    const signOut = async () => {
        localStorage.removeItem('hustleToken');
        dispatch(logout());
    };

    const toggleMenu = () => {
        let distanceFromTop = window.pageYOffset;

        return function () {
            let newDistanceFromTop = window.pageYOffset;
            if (
                newDistanceFromTop < 50 ||
                distanceFromTop >= newDistanceFromTop
            ) {
                setShowMenu(true);
            } else {
                setShowMenu(false);
            }
            if (newDistanceFromTop < 50) {
                setScrollOnTop(true);
            } else {
                setScrollOnTop(false);
            }
            distanceFromTop = newDistanceFromTop;
        };
    };

    useEffect(() => {
        setTimeout(() => setShowMenu(true), 500);
        window.addEventListener('scroll', toggleMenu());
        return window.removeEventListener('scroll', toggleMenu());
    }, []);

    return (
        <div
            className={`${styles.wrap} ${
                props.active && showMenu && styles.showMenu
            } ${scrollOnTop && styles.scrollOnTop}`}
        >
            <Container>
                <div className={styles.content}>
                    <Logo />
                    <nav className={styles.nav}>
                        <NavLink
                            to="/plan"
                            className={({ isActive }: { isActive: any }) =>
                                isActive
                                    ? `${styles.navItem} ${styles.active}`
                                    : styles.navItem
                            }
                        >
                            Plan
                        </NavLink>
                        <NavLink
                            to="/execute"
                            className={({ isActive }: { isActive: any }) =>
                                isActive
                                    ? `${styles.navItem} ${styles.active}`
                                    : styles.navItem
                            }
                        >
                            Execute
                        </NavLink>
                    </nav>
                    {userState.data && (
                        <Dropdown
                            position="bottomRight"
                            trigger={
                                <Button size="m" theme="plain">
                                    <p>{userState.data.userEmail}</p>
                                </Button>
                            }
                        >
                            <ThemeToggle />
                            <FeatureFlag feature="automation">
                                <Button size="m" theme="blank" to="/automation">
                                    <AutomateIcon /> <p>Automate</p>
                                </Button>
                            </FeatureFlag>
                            <FeatureFlag feature="subscription">
                                <Button
                                    size="m"
                                    theme="blank"
                                    to="/subscription"
                                >
                                    <PricetagIcon /> <p>Subscription</p>
                                </Button>
                            </FeatureFlag>
                            <Separator />
                            <Button size="m" theme="blank" onClick={signOut}>
                                <PowerIcon /> <p>Logout</p>
                            </Button>
                        </Dropdown>
                    )}
                </div>
            </Container>
        </div>
    );
};

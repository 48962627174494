// projectsThunks.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'api';
import {
    AddProjectCommand,
    ReadArchivedProjectsResponse,
    RenameProjectCommand,
} from 'api/project-model';

export const addProject = createAsyncThunk(
    'projects/addProject',
    async (command: AddProjectCommand, { rejectWithValue }) => {
        try {
            return await Api.defaultApi().addProject(command);
        } catch (err: any) {
            if (!err.response) throw err;
            return rejectWithValue(err.response.data);
        }
    }
);

export const renameProject = createAsyncThunk(
    'projects/renameProject',
    async (
        props: { projectId: string; command: RenameProjectCommand },
        { rejectWithValue }
    ) => {
        try {
            await Api.defaultApi().renameProject(
                props.projectId,
                props.command
            );
            return { request: props, response: '' };
        } catch (err: any) {
            if (!err.response) throw err;
            return rejectWithValue(err.response.data);
        }
    }
);

export const moveProject = createAsyncThunk(
    'projects/moveProject',
    async (
        props: {
            workspaceId: string;
            projectId: string;
            itemIndex: number;
            newIndex: number;
        },
        { rejectWithValue }
    ) => {
        try {
            await Api.defaultApi().moveProject(
                props.workspaceId,
                props.projectId,
                props.itemIndex,
                props.newIndex
            );
            return {
                itemIndex: props.itemIndex,
                newIndex: props.newIndex,
            };
        } catch (err: any) {
            if (!err.response) throw err;
            return rejectWithValue(err.response.data);
        }
    }
);

export const fetchArchivedProjects = createAsyncThunk(
    'workspace/fetchArchivedProjects',
    async (workspaceId: string): Promise<ReadArchivedProjectsResponse> =>
        await Api.defaultApi().fetchArchivedProjects(workspaceId)
);

export const archiveProject = createAsyncThunk(
    'projects/archiveProject',
    async (projectId: string, { rejectWithValue }) => {
        try {
            await Api.defaultApi().archiveProject(projectId);
            return projectId;
        } catch (err: any) {
            if (!err.response) throw err;
            return rejectWithValue(err.response.data);
        }
    }
);

export const unarchiveProject = createAsyncThunk(
    'projects/unarchiveProject',
    async (projectId: string, { rejectWithValue }) => {
        try {
            return await Api.defaultApi().unarchiveProject(projectId);
        } catch (err: any) {
            if (!err.response) throw err;
            return rejectWithValue(err.response.data);
        }
    }
);

export const deleteProject = createAsyncThunk(
    'projects/deleteProject',
    async (projectId: string, { rejectWithValue }) => {
        try {
            await Api.defaultApi().deleteProject(projectId);
            return projectId;
        } catch (err: any) {
            if (!err.response) throw err;
            return rejectWithValue(err.response.data);
        }
    }
);

import { ReactComponent as LogoFull } from '../../../assets/logo/hustle-full.svg';
import styles from './logo.module.scss';

const Logo = (props: { center?: boolean }) => {
    return (
        <div className={`${styles.wrap} ${props.center && styles.center}`}>
            <LogoFull />
        </div>
    );
};

export default Logo;

import { ReactComponent as ArchiveIcon } from 'assets/icons/archive.svg';
import { ReactComponent as InviteIcon } from 'assets/icons/person-plus.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import Button from 'atoms/Button';
import Dropdown from 'atoms/Dropdown';
import TextEditable from 'atoms/TextEditable';
import { ProjectState } from 'core/model';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { renameProject } from 'store/projectsThunks';
import { AppDispatch } from '../../../store';
import { Invite } from './Invite';
import styles from './Project.module.scss';

const Header = (props: {
    workspaceId: string;
    project: ProjectState;
    onProjectDelete: any;
    onProjectArchive: any;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [newProjectName, setNewProjectName] = useState<string>(
        props.project.name
    );
    const [isEditingProjectName, setIsEditingProjectName] =
        useState<boolean>(false);
    const [inviteInfoOpen, setInviteInfoOpen] = useState<boolean>(false);

    const startRenamingProject = () => {
        setIsEditingProjectName(true);
    };

    const handleChange = (event: any) => {
        setNewProjectName(event.target.value);
    };

    const doRenameProject = (name: string) => {
        dispatch(
            renameProject({
                projectId: props.project.id,
                command: {
                    workspaceId: props.workspaceId,
                    name: name,
                },
            })
        );
    };

    const renamingConfirmed = () => {
        if (newProjectName && newProjectName.trim()) {
            doRenameProject(newProjectName.trim());
            setNewProjectName(newProjectName.trim());
            setIsEditingProjectName(false);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            renamingConfirmed();
        } else if (event.key === 'Escape') {
            setIsEditingProjectName(false);
        }
    };

    const onRenameConfirmedButtonClicked = () => {
        renamingConfirmed();
    };

    const handleBlur = () => {
        setIsEditingProjectName(false);
    };

    if (isEditingProjectName) {
        return (
            <div
                onBlur={handleBlur}
                className={styles.header}
                style={{ borderColor: props.project.color || '' }}
            >
                <TextEditable
                    value={newProjectName}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                    maxLength={100}
                    placeholder="Enter text (max 100 characters)"
                    onRenameConfirmedButtonClicked={
                        onRenameConfirmedButtonClicked
                    }
                />
            </div>
        );
    }

    const onInviteClicked = () => {
        setInviteInfoOpen(true);
    };

    const ownProject = !props.project.shared;

    return (
        <>
            <div
                className={styles.header}
                style={{ color: props.project.color || '' }}
            >
                <h2 onClick={startRenamingProject}>{props.project.name}</h2>
                {ownProject && (
                    <div>
                        <Dropdown position="bottomRight" horizontal={true}>
                            <Button size="m" onClick={onInviteClicked}>
                                <InviteIcon /> <p>Invite</p>
                            </Button>
                            <Button size="m" onClick={props.onProjectArchive}>
                                <ArchiveIcon /> <p>Archive</p>
                            </Button>
                            <Button size="m" onClick={props.onProjectDelete}>
                                <TrashIcon /> <p>Delete</p>
                            </Button>
                        </Dropdown>
                    </div>
                )}
            </div>
            {inviteInfoOpen && (
                <Invite
                    opened={inviteInfoOpen}
                    workspaceId={props.workspaceId}
                    projectId={props.project.id}
                    handleModalClose={() => setInviteInfoOpen(false)}
                />
            )}
        </>
    );
};

export default Header;

import clsx from 'clsx';
import Button from 'layout/atoms/Button';
import Dropdown from 'layout/atoms/Dropdown';
import TaskCheckbox from 'layout/atoms/TaskCheckbox/TaskCheckbox';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as DragIcon } from 'assets/icons/drag-indicator.svg';
import { ReactComponent as MoveBackIcon } from 'assets/icons/move-back.svg';
import { CSSProperties, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';
import { plannedTaskAction, taskAction } from 'store/tasksThunks';
import { selectTask } from 'store/workspaceSlice';
import { stateToColor } from '../../../containers/Workspace/themes';
import styles from './TaskExecute.module.scss';

export const TaskExecute = ({
    id,
    done = false,
    state,
}: {
    id: string;
    done?: boolean;
    state: string;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const taskDataFromStore = useSelector((state: any) =>
        selectTask(state, id)
    );
    const [checkedState, setCheckedState] = useState<boolean>(
        taskDataFromStore.done
    );
    const {
        isDragging,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };

    const inlineStyles: CSSProperties = {
        opacity: isDragging ? '0.5' : '1',
        transformOrigin: '50% 50%',
        cursor: isDragging ? 'grabbing' : 'grab',
        ...style,
    };

    const removeFromPlanned = () => {
        dispatch(
            taskAction({
                taskId: id,
                action: 'IDLE',
                command: {
                    origin:
                        taskDataFromStore.automationId === undefined
                            ? 'workspace'
                            : 'automation',
                    workspaceId: taskDataFromStore.projectRef.workspaceId,
                    projectId: taskDataFromStore.projectRef.projectId,
                },
            })
        );
    };

    const taskClicked = (checked: boolean) => {
        setTimeout(() => setCheckedState(checked), checked ? 0 : 500);
        setTimeout(
            () =>
                dispatch(
                    taskDataFromStore.automationId === undefined
                        ? plannedTaskAction({
                              taskId: id,
                              action: checked ? 'COMPLETE' : 'REVERT',
                              command: {
                                  origin: 'workspace',
                                  workspaceId:
                                      taskDataFromStore.projectRef.workspaceId,
                                  projectId:
                                      taskDataFromStore.projectRef.id ||
                                      taskDataFromStore.projectRef.workspaceId,
                              },
                          })
                        : plannedTaskAction({
                              taskId: taskDataFromStore.automationId,
                              action: checked ? 'COMPLETE' : 'REVERT',
                              command: {
                                  origin: 'automation',
                                  workspaceId:
                                      taskDataFromStore.projectRef.workspaceId,
                                  projectId:
                                      taskDataFromStore.projectRef.id ||
                                      taskDataFromStore.projectRef.workspaceId,
                              },
                          })
                ),
            checked ? 500 : 0
        );
    };

    return (
        <div
            className={clsx(styles.wrap, styles[`wrap-${state}`], {
                [styles.checked]: checkedState,
            })}
            style={
                state === 'planned' ? inlineStyles : { transformOrigin: '0' }
            }
            ref={setNodeRef}
        >
            <div
                className={clsx(styles.content, styles[`content-${state}`], {
                    [styles.checked]: checkedState,
                })}
            >
                <div
                    className={clsx(styles.indicator, {
                        [styles.cursor]: state === 'planned',
                    })}
                    style={{
                        background: taskDataFromStore?.projectRef?.color,
                    }}
                    {...listeners}
                    {...attributes}
                >
                    {state === 'planned' && <DragIcon />}
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: stateToColor(taskDataFromStore.state),
                        flex: '1',
                        alignContent: 'center',
                    }}
                >
                    <span style={{ marginRight: '14px' }}>
                        <TaskCheckbox
                            color={taskDataFromStore?.projectRef?.color}
                            checked={taskDataFromStore.done}
                            onChange={taskClicked}
                        />
                    </span>
                    {taskDataFromStore.name}
                </div>
                {!taskDataFromStore.done && (
                    <div>
                        <Dropdown position="bottomRight" horizontal={true}>
                            <Button onClick={removeFromPlanned}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100vh',
                                    }}
                                >
                                    <MoveBackIcon
                                        style={{ display: 'inline' }}
                                    />
                                    <span style={{ marginLeft: '6px' }}>
                                        Move&nbsp;to&nbsp;planning
                                    </span>
                                </div>
                            </Button>
                        </Dropdown>
                    </div>
                )}
                <div
                    style={{
                        position: 'absolute',
                        bottom: '2px',
                        right: '24px',
                        fontSize: '0.75em',
                        color: taskDataFromStore?.projectRef?.color, // Assuming props.color is defined
                        whiteSpace: 'nowrap',
                        flexShrink: 0,
                    }}
                >
                    <span style={{ position: 'relative', bottom: '0px' }}>
                        {taskDataFromStore?.projectRef?.name}{' '}
                    </span>
                </div>
            </div>
        </div>
    );
};

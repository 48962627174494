import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import { useDispatch, useSelector } from 'react-redux';

import Benefit from 'layout/atoms/Benefit';
import Button from 'layout/atoms/Button';
import Container from 'layout/atoms/Container';
import ContentBox from 'layout/atoms/ContentBox';
import Grid from 'layout/atoms/Grid';
import ListHeader from 'layout/atoms/ListHeader/ListHeader';
import Switch from 'layout/atoms/Switch';
import { useEffect, useState } from 'react';

import SubscriptionPlanBox from 'layout/molecules/SubscriptionPlanBox';

import SkeletonLoader from 'layout/atoms/SkeletonLoader';

import { AppDispatch } from 'store';
import { getUserSubscription, selectUserSubscription } from 'store/userSlice';

const SubscriptionPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const userSubscription = useSelector(selectUserSubscription);
    const [period, setPeriod] = useState<'month' | 'annual'>('month');
    const currentPlanIsFree = userSubscription.plan === 'FREE';
    const currentPlanIsTrial =
        userSubscription.plan === 'PREMIUM' && userSubscription.isTrial;
    const currentPlanIsPremium =
        userSubscription.plan === 'PREMIUM' && !userSubscription.isTrial;
    const currentPlanIsNotPremium =
        userSubscription.plan !== 'PREMIUM' || userSubscription.isTrial;

    useEffect(() => {
        userSubscription.status === 'IDLE' && dispatch(getUserSubscription());
    }, [dispatch, userSubscription.status]);

    const freeBenefits = () => (
        <Grid columns="1" gap={12}>
            <Benefit active>5 projects</Benefit>
            <Benefit active>Unlimited tasks in project</Benefit>
            <Benefit active>2 automations</Benefit>
            <Benefit>Share and cooperate on projects</Benefit>
            <Benefit>24/7 Support</Benefit>
        </Grid>
    );

    const premiumBenefits = () => (
        <Grid columns="2" gap={12}>
            <Benefit active>Unlimited projects</Benefit>
            <Benefit active>Unlimited tasks in project</Benefit>
            <Benefit active>Unlimited automations</Benefit>
            <Benefit active>Share and cooperate on projects</Benefit>
            <Benefit active>24/7 Support</Benefit>
            <Benefit active={period === 'annual'}>Saving 20%</Benefit>
        </Grid>
    );

    if (
        userSubscription.status === 'IDLE' ||
        userSubscription.status === 'LOADING'
    ) {
        return (
            <Container>
                <ListHeader text="Subscription" />
                <Grid columns="2" gap={16}>
                    <ContentBox>
                        <SubscriptionPlanBox
                            id="001"
                            name={<SkeletonLoader width="70px" height="32px" />}
                            monthPrice="0"
                            pricePeriod={period}
                            current
                            benefits={
                                <SkeletonLoader width="120px" height="24px" />
                            }
                            buttons={
                                <>
                                    <SkeletonLoader
                                        width="100%"
                                        height="54px"
                                    />
                                    <SkeletonLoader
                                        width="100%"
                                        height="54px"
                                    />
                                </>
                            }
                        />
                    </ContentBox>
                </Grid>
            </Container>
        );
    }

    return (
        <Container>
            <Grid columns="1" gap={16}>
                <ListHeader text="Subscription" />
                <Grid columns="2" gap={16}>
                    <ContentBox>
                        <SubscriptionPlanBox
                            id="001"
                            monthPrice={userSubscription.plan || ''}
                            name={
                                currentPlanIsTrial ? (
                                    <>
                                        <strong>Free Trial</strong>
                                    </>
                                ) : null
                            }
                            pricePeriod={period}
                            current
                            benefits={
                                !currentPlanIsFree && (
                                    <p>
                                        Valid unitl:{' '}
                                        <strong>
                                            {dayjs(
                                                currentPlanIsTrial
                                                    ? userSubscription.trialEndDate
                                                    : userSubscription.expirationDate
                                            ).format('D MMMM YYYY')}
                                        </strong>
                                    </p>
                                )
                            }
                            buttons={
                                <>
                                    {currentPlanIsNotPremium && (
                                        <Button size="xxl" theme="primary">
                                            Upgrade to Premium
                                        </Button>
                                    )}
                                    {currentPlanIsFree && (
                                        <Button size="xxl" theme="plain">
                                            Extend your Free Trial
                                        </Button>
                                    )}
                                </>
                            }
                        />
                    </ContentBox>
                </Grid>
                <ListHeader text="Pricing" />
                <Switch
                    name="period"
                    value={period === 'annual'}
                    label="Annualy"
                    leftLabel="Billed monthly"
                    onChange={() =>
                        setPeriod(period === 'month' ? 'annual' : 'month')
                    }
                />
                <Grid columns="5_7" gap={16}>
                    <ContentBox>
                        <SubscriptionPlanBox
                            id="001"
                            name={
                                <>
                                    <strong>Free</strong>
                                </>
                            }
                            monthPrice="0$"
                            pricePeriod={period}
                            benefits={freeBenefits()}
                            buttons={
                                currentPlanIsPremium && (
                                    <Button size="xxl" theme="plain">
                                        Downgrade to Free
                                    </Button>
                                )
                            }
                        />
                    </ContentBox>
                    <ContentBox>
                        <SubscriptionPlanBox
                            id="002"
                            name={
                                <>
                                    <strong>Premium</strong>
                                </>
                            }
                            monthPrice="5$"
                            annualPrice="4$"
                            pricePeriod={period}
                            benefits={premiumBenefits()}
                            buttons={
                                currentPlanIsNotPremium && (
                                    <Button size="xxl" theme="primary">
                                        Upgrade to Premium
                                    </Button>
                                )
                            }
                        />
                    </ContentBox>
                </Grid>
            </Grid>
        </Container>
    );
};

export default SubscriptionPage;

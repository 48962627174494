import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
export type ToastType = 'success' | 'error' | 'info' | 'alert';

interface Toast {
    id: number;
    type: ToastType;
    message: string;
    title: string;
    description: string;
}

interface State {
    toasts: Toast[];
}

const initialState: State = {
    toasts: [],
};

let id = 0;

const toastsSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        addNewToast: (state, action) => {
            state.toasts.push(action.payload);
        },
        removeToast: (state, action) => {
            state.toasts = state.toasts.filter(
                (toast) => toast.id !== action.payload
            );
        },
    },
});

export const { addNewToast, removeToast } = toastsSlice.actions;

export const selectAllToasts = (state: RootState) => state.toasts.toasts;

export const addToast = (toast: Toast) => (dispatch: AppDispatch) => {
    const values = toast
        ? toast
        : {
              type: 'error',
          };

    dispatch(
        addNewToast({
            ...values,
            id: id++,
        })
    );
};

export default toastsSlice.reducer;

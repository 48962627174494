import LinkSvg from 'assets/icons/external-link.svg';
import Linkify from 'react-linkify';

const stopPropagation = (event: any) => event.stopPropagation();

export const TaskName = (props: { name: string }) => (
    <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => {
            const trimmedText = decoratedText
                .replace('https://', '')
                .replace('http://', '')
                .replace('www.', '');
            const ellipsis = trimmedText.length > 27 ? '...' : '';

            return (
                <a
                    target="blank"
                    href={decoratedHref}
                    key={key}
                    onClick={stopPropagation}
                    title={decoratedHref}
                >
                    <span>
                        {trimmedText.substring(0, 27)}
                        {ellipsis}
                    </span>
                    <img src={LinkSvg} alt="external link" />
                </a>
            );
        }}
    >
        {props.name}
    </Linkify>
);

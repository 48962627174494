import { ReactComponent as MoveBackIcon } from 'assets/icons/move-back.svg';
import AddTask from 'containers/Plan/AddTask';
import {
    ProjectId,
    ProjectRef,
    TaskState,
    TaskStateObject,
    WorkspaceId,
} from 'core/model';
import Button from 'layout/atoms/Button';
import Container from 'layout/atoms/Container';
import Dropdown from 'layout/atoms/Dropdown';
import Grid from 'layout/atoms/Grid';
import TaskExecute from 'layout/atoms/TaskExecute';
import PlannedTasksList from 'layout/organisms/PlannedTasksList/PlannedTasksList';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';
import {
    addTask,
    hideCompletedTasksAction,
    idleAllPlannedTasksAction,
} from 'store/tasksThunks';
import { selectWorkspaceId } from 'store/userSlice';
import { selectWorkspaceData, WorkspaceState } from 'store/workspaceSlice';

export type PlannedTaskOrigin = 'workspace' | 'project' | 'automation';

export type PlannedTask = {
    task: TaskStateObject;
    color?: string | null;
    projectName?: string;
    projectRef: ProjectRef;
    origin: PlannedTaskOrigin;
};

const ExecutePage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const workspaceId = useSelector(selectWorkspaceId) || '';
    const workspaceData: WorkspaceState = useSelector(selectWorkspaceData);

    const activeTasks: PlannedTask[] = [
        ...workspaceData.tasks
            .filter(
                (t) => t.done || workspaceData.plannedTasks.indexOf(t.id) >= 0
            )
            .map((task) => {
                return {
                    task,
                    projectRef: {
                        workspaceId: workspaceData.id as WorkspaceId,
                        projectId: workspaceData.id as ProjectId,
                    },
                    origin: 'workspace' as PlannedTaskOrigin,
                };
            }),
        ...workspaceData.projects.flatMap((p) => {
            return p.tasks
                .filter(
                    (t: any) =>
                        t.done || workspaceData.plannedTasks.indexOf(t.id) >= 0
                )
                .map((task: any) => {
                    return {
                        task,
                        color: p.color,
                        projectName: p.name,
                        projectRef: {
                            workspaceId: p.workspaceId as WorkspaceId,
                            projectId: p.id,
                        },
                        origin: 'project' as PlannedTaskOrigin,
                    };
                });
        }),
        ...workspaceData.automations.map((a) => {
            return {
                task: {
                    id: a.id,
                    name: a.name,
                    planned: true,
                    state: 'PLANNED' as TaskState,
                    createdAt: new Date().toISOString(),
                    completedAt: a.completedAt,
                    done: false,
                },
                projectRef: {
                    workspaceId: workspaceData.id as WorkspaceId,
                    projectId: a.id as ProjectId,
                },
                origin: 'automation' as PlannedTaskOrigin,
            };
        }),
    ].sort(
        (t1, t2) =>
            workspaceData.plannedTasks.indexOf(t1.task.id) -
            workspaceData.plannedTasks.indexOf(t2.task.id)
    );

    const partitionedTasks = activeTasks.reduce(
        (acc, plannedTask) => {
            const group: PlannedTask[] =
                acc[plannedTask.task.completedAt === null ? 0 : 1];
            group.push(plannedTask);
            return acc;
        },
        [[], []]
    );
    const _plannedTasks: PlannedTask[] = partitionedTasks[0];
    const completedTasks: PlannedTask[] = partitionedTasks[1];
    const displaySeparator =
        _plannedTasks.length > 0 && completedTasks.length > 0;

    const hideCompletedTasks = () => {
        dispatch(hideCompletedTasksAction());
    };

    const idleAllPlannedTasks = () => {
        dispatch(idleAllPlannedTasksAction());
    };

    const onQuickTasksAdded = async (tasks: string[]) => {
        for (let i = 0; i < tasks.length; i++) {
            const t = tasks[i];
            await dispatch(
                addTask({
                    workspaceId: workspaceId,
                    projectId: workspaceId,
                    name: t,
                    planned: true,
                })
            );
        }
    };

    return (
        <>
            <Container narrow>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <AddTask
                        buttonText={'+ Quick Add'}
                        placeholder={'Quick add a task to your workspace'}
                        onTasksAdded={onQuickTasksAdded}
                    />
                    {workspaceData.plannedTasks.length > 0 && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Dropdown position="bottomRight" horizontal={true}>
                                <Button onClick={idleAllPlannedTasks}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100vh',
                                        }}
                                    >
                                        <MoveBackIcon
                                            style={{ display: 'inline' }}
                                        />
                                        <span style={{ marginLeft: '6px' }}>
                                            Move&nbsp;all&nbsp;tasks&nbsp;to&nbsp;planning
                                        </span>
                                    </div>
                                </Button>
                            </Dropdown>
                        </div>
                    )}
                </div>
            </Container>
            <PlannedTasksList />
            <Container narrow>
                <>
                    {displaySeparator ? (
                        <div>
                            <small>Completed tasks</small>
                        </div>
                    ) : null}
                    {completedTasks.length > 0 ? (
                        <div>
                            <Button onClick={hideCompletedTasks}>
                                Hide completed tasks
                            </Button>
                        </div>
                    ) : null}
                </>
                {completedTasks.length > 0 && (
                    <Grid columns={1} gap={8}>
                        {completedTasks
                            .sort((t1, t2) =>
                                t2.task.state.localeCompare(t1.task.state)
                            )
                            .map((task) => (
                                <TaskExecute
                                    key={task.task.id}
                                    id={task.task.id}
                                    state="completed"
                                />
                            ))}
                    </Grid>
                )}
            </Container>
        </>
    );
};

export default ExecutePage;

import { TaskState } from 'core/model';

export const stateToColor = (state: TaskState): string => {
    switch (state) {
        case 'PLANNED':
            return 'var(--text)';
        case 'DONE':
            return 'var(--text)';
        case 'IDLE':
            return 'gray';
    }
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Popover } from '@headlessui/react';

import ContentBox from '../ContentBox';
import { ReactComponent as MenuIcon } from '../../../assets/icons/dots-vertical.svg';
import { ReactComponent as MenuIconHorizontal } from '../../../assets/icons/dots-horizontal.svg';

import styles from './dropdown.module.scss';
import Grid from '../Grid';
import Portal from '../Portal';
import Button from '../Button';

type DropdownProps = {
    position?:
        | 'bottomLeft'
        | 'bottomCenter'
        | 'bottomRight'
        | 'topLeft'
        | 'topCenter'
        | 'topRight';
    horizontal?: boolean;
    trigger?: React.ReactNode | React.ReactNode[];
    children: React.ReactNode | React.ReactNode[];
};

const Dropdown: React.FC<DropdownProps> = ({
    position,
    trigger,
    children,
    horizontal,
}: DropdownProps): JSX.Element => {
    const triggerRef = useRef<HTMLButtonElement>(null);
    const [panelStyles, setPanelStyles] = useState<
        React.CSSProperties | undefined
    >(undefined);

    const calculatePosition = useCallback(() => {
        if (!triggerRef.current) return;

        const rect = triggerRef.current.getBoundingClientRect();
        const styles: React.CSSProperties = {
            position: 'absolute',
            top: rect.bottom + window.scrollY,
            left: rect.left + window.scrollX,
        };

        switch (position) {
            case 'bottomCenter':
                styles.left = rect.left + window.scrollX + rect.width / 2;
                styles.transform = 'translateX(-50%)';
                break;
            case 'bottomRight':
                styles.left = rect.right + window.scrollX;
                styles.transform = 'translateX(-100%)';
                break;
            case 'topLeft':
                styles.top = rect.top + window.scrollY;
                styles.left = rect.left + window.scrollX;
                styles.transform = 'translateY(-100%)';
                break;
            case 'topCenter':
                styles.top = rect.top + window.scrollY;
                styles.left = rect.left + window.scrollX + rect.width / 2;
                styles.transform = 'translate(-50%, -100%)';
                break;
            case 'topRight':
                styles.top = rect.top + window.scrollY;
                styles.left = rect.right + window.scrollX;
                styles.transform = 'translate(-100%, -100%)';
                break;
            default:
                break;
        }

        setPanelStyles(styles);
    }, [position]);

    useEffect(() => {
        window.addEventListener('resize', calculatePosition);
        return () => window.removeEventListener('resize', calculatePosition);
    }, [calculatePosition]);

    useEffect(() => {
        const observer = new MutationObserver(() => calculatePosition());
        if (triggerRef.current) {
            observer.observe(document.body, { childList: true, subtree: true });
        }
        return () => observer.disconnect();
    }, [calculatePosition]);

    return (
        <Popover className={`${styles.wrapper}`}>
            <Popover.Button
                className={`${styles.button}`}
                ref={triggerRef}
                onClick={calculatePosition}
                as="div"
            >
                {trigger ? (
                    trigger
                ) : horizontal ? (
                    <Button size="m" theme="blank" shape="square">
                        <MenuIconHorizontal />
                    </Button>
                ) : (
                    <Button size="m" theme="blank" shape="square">
                        <MenuIcon />
                    </Button>
                )}
            </Popover.Button>
            <Portal className={styles.portal}>
                <Popover.Panel
                    className={`${styles.box} ${
                        position ? styles[position] : styles.bottomLeft
                    }`}
                    style={panelStyles}
                >
                    <ContentBox padding="s">
                        <Grid columns="1">{children}</Grid>
                    </ContentBox>
                </Popover.Panel>
            </Portal>
        </Popover>
    );
};

export default Dropdown;

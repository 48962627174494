import { ProjectId, TaskStateObject, WorkspaceId } from 'core/model';
import { fetchWorkspace } from 'store/workspaceThunks';

export const workspaceReducers = (builder: any) => {
    builder
        // fetchWorkspace
        .addCase(fetchWorkspace.pending, (state: any) => {
            state.status = 'LOADING';
            state.error = null;
        })
        .addCase(fetchWorkspace.fulfilled, (state: any, action: any) => {
            state.status = 'SUCCEEDED';
            state.id = action.payload.workspaceId;
            state.projects = action.payload.projects.map((p: any) => {
                const projectTasks: TaskStateObject[] = p.tasks.map(
                    (t: any) => {
                        return {
                            ...t,
                            state: !!t.completedAt
                                ? 'DONE'
                                : action.payload.plannedTasks.indexOf(t.id) > -1
                                ? 'PLANNED'
                                : 'IDLE',
                        };
                    }
                );
                return {
                    ...p,
                    id: p.id as ProjectId,
                    workspaceId: (p.shared?.workspaceId ||
                        action.payload.workspaceId) as WorkspaceId,
                    projectRef: {
                        projectId: p.id as ProjectId,
                        workspaceId: action.payload.workspaceId as WorkspaceId,
                    },
                    tasks: projectTasks,
                    shared:
                        p.shared == null
                            ? null
                            : {
                                  workspaceId: p.shared
                                      .workspaceId as WorkspaceId,
                                  ownerId: p.shared.ownerId,
                              },
                };
            });
            state.tasks = action.payload.tasks.map((t: any) => {
                return {
                    ...t,
                    state: !!t.completedAt
                        ? 'DONE'
                        : action.payload.plannedTasks.indexOf(t.id) > -1
                        ? 'PLANNED'
                        : 'IDLE',
                };
            });
            state.archivedProjectsCount = action.payload.archivedProjectsCount;
            state.plannedTasks = action.payload.plannedTasks;
            state.automations = action.payload.automations.map((t: any) => {
                return {
                    ...t,
                    state: !!t.completedAt
                        ? 'DONE'
                        : action.payload.plannedTasks.indexOf(t.id) > -1
                        ? 'PLANNED'
                        : 'IDLE',
                };
            });
        })
        .addCase(fetchWorkspace.rejected, (state: any, action: any) => {
            state.status = 'FAILED';
            state.error = action.error.message || 'An error occurred';
        });
};

import Task from 'layout/atoms/Task';
import styles from 'layout/molecules/Project/Project.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/index';
import { addTask } from 'store/tasksThunks';
import { selectWorkspaceId } from 'store/userSlice';
import { selectWorkspaceData, WorkspaceState } from 'store/workspaceSlice';
import AddTask from './AddTask';

const WorkspaceTasks = () => {
    const dispatch = useDispatch<AppDispatch>();
    const workspaceId = useSelector(selectWorkspaceId);
    const workspaceData: WorkspaceState = useSelector(selectWorkspaceData);
    let filteredTasks = workspaceData.tasks.filter((t) => t.state !== 'DONE');

    const onWorkspaceTasksAdded = async (tasks: string[]) => {
        if (tasks && tasks.length > 0) {
            for (let i = 0; i < tasks.length; i++) {
                const t = tasks[i];
                await dispatch(
                    addTask({
                        workspaceId: workspaceId || '',
                        projectId: workspaceId,
                        name: t,
                        planned: false,
                    })
                );
            }
        }
    };

    return (
        <div className={styles.wrap} style={{ margin: '0 auto', padding: '0' }}>
            <div className={styles.main}>
                {filteredTasks.map((t) => (
                    <Task
                        key={t.id}
                        task={t}
                        projectRef={{
                            workspaceId: workspaceData.id,
                            projectId: workspaceData.id,
                        }}
                    ></Task>
                ))}
            </div>
            <div className={styles.footer}>
                <AddTask
                    placeholder={'Add a task to your workspace'}
                    onTasksAdded={onWorkspaceTasksAdded}
                />
            </div>
        </div>
    );
};

export default WorkspaceTasks;
